<template>
  <div id="single-blog">
    <div class="breadcrumbs">
      <div class="container">
        <ul class="items">
          <li class="item home">
            <router-link to="/">Home </router-link>
          </li>
          <li class="item blog">
            <strong>{{ blogcontents.title }}</strong>
          </li>
        </ul>
      </div>
    </div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12">
          <h2 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">{{
              blogcontents.title
            }}</span>
          </h2>
          <div class="post-view">
            <div class="post-holder post-holder-5">
              <div class="post-content">
                <div class="post-description clearfix">
                  <div class="post-ftimg-hld">
                    <img :src="blogcontents.blog_image" />
                  </div>
                  <div class="post-text-hld" v-html="description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleBlog",
  data() {
    return {
      description: "",
      blogcontents: [],
    };
  },
  created() {
    this.getBlogsDetails();
  },
  methods: {
    getBlogsDetails() {
      var segment_str = window.location.pathname;
      var segment_array = segment_str.split("/");
      var last_segment = segment_array.pop();
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/blog/getBlogDetails";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          slug: last_segment,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.blogcontents != null) {
            this.description = data.blogcontents.description;
            this.blogcontents = data.blogcontents;
          }
          this.loader = false;
        });
    },
  },
};
</script>
